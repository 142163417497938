.menu-social {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  width: 100%;

  margin: 0;
  padding: 20px 0;

  list-style: none;
  
  @media (max-width: 992px) {
    order: 3;
  }
}
