.gratitudes-by-year-screen {
  &__gratitude-items-list {
    margin-bottom: 90px;
    padding: 0 40px 0 0 !important;
  }

  &__gratitude-item,
  &__gratitude-tag {
    padding-left: 0 !important;
  }

  &__gratitude-tag {
    margin: 0;

    @include font-text-inset;
    font-style: italic;
  }
  
  &__description {
    display: none;
  }

  &__gratitudes-item-empty {
    margin: 30px 0;

    @include font-text-big;
    text-align: center;
  }

  .slick-arrow ~ .slick-list {
    margin-bottom: 50px;
  }

  .slick-arrow {
    margin-bottom: 50px;
  }
  
  .slick-slide > div {
    position: relative;
  }

  .slick-prev, 
  .slick-next {
    width: 60px;
    height: 50px;
  
    top: unset;
    left: unset;
    right: 120px;

    border: 1px solid #000;
    border-radius: 50%;

    z-index: 100;
    background-color: #fff;

    &::after { 
      @include arrow-btn;
      top: 12px;
      left: 10px;
    }

    &:hover::after,
    &:focus::after {
      transform: translateX(8px);
    }
  
    &:active::after {
      transform: translateX(16px);
    }
  }

  .slick-next {
    bottom: -80px;
  }

  .slick-prev {
    bottom: -55px;
    right: 200px;

    transform: rotate(180deg);
  }

  @media (max-width: 1660px) {
    .gratitudes-item__photo {
      max-width: 300px;
    }
  }

  @media (max-width: 1440px) {
    &__gratitude-item,
    &__gratitude-tag {
      padding-left: inherit;
    }

    &__gratitude-tag {
      font-size: 20px;
      line-height: 48px;
    }

    .gratitudes-item__photo {
      max-width: 350px;
    }
  }

  @media (max-width: 1260px) {
    &__gratitude-items-list {
      margin-bottom: 80px;
    }

    .slick-next {
      right: 45px;
    }

    .slick-prev {
      right: 115px;
    }

    .gratitudes-item__photo {
      max-width: 300px;
    }
  }

  @media (max-width: 992px) {
    .slick-next {
      right: 0;
    }

    .slick-prev {
      right: 70px;
    }

    .gratitudes-item__photo {
      max-width: 220px;
    }
  }

  @media (max-width: 767px) {
    &__gratitude-tag {
      text-align: center;
      font-size: 18px;
    }

    .gratitudes-item__photo {
      max-width: 350px;
      margin: 0 auto;
    }

    &__gratitudes-item-empty {
      @include font-text-small;
    }

    .slick-prev {
      left: calc(50% - 67px);
      right: unset;
    }

    .slick-next {
      right: calc(50% - 67px);
    }
  }
}
