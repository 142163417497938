.footer {
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  position: relative;

  padding: 28px 60px 30px;

  text-align: center;
  border-top: 1px solid #000;

  &__menu-social {
    position: absolute;
    top: calc(50% - 40px);
    left: 60px;
  }

  &__copyright {
    align-self: center;
    margin: 0;

    @include font-menu;
  }

  @media (max-width: 1260px) {
    &__menu-social {
      display: none;
    }
  }

  @media (max-width: 768px) {
    padding-left: 25px;
    padding-right: 25px;

    &__copyright {
      font-size: 16px;
      line-height: 20px;
    }
  }
}