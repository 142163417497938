.main-page-about-block {
  &__title {
    margin: 0 0 50px;

    @include font-title-small;
  }

  &__description {
    position: relative;
    padding: 0 60px;
  }

  &__description-part {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 40px;

    div {
      width: 50%;

      &:first-child {
        margin-right: 100px;
      }
    }
  }

  &__img {
    display: block;
    width: auto;
  }

  &__text {
    margin: 0 0 40px;

    @include font-text-big;
    line-height: 30px;
  }

  @media (max-width: 1260px) {
    &__title {
      font-size: 30px;
    }

    &__description {
      padding: 0 25px !important;
    }

    &__description-part {
      padding: 0;
    }

    &__text {
      @include font-text-small;
    }
  }

  @media (max-width: 768px) {
    &__title {
      font-size: 24px;
      line-height: 40px;
    }

    &__description-part {
      flex-direction: column;

      div {
        width: 100%;
      }
    }

    &__img-wrapper {
      order: 1;
      margin-bottom: 50px;
    }

    &__text-wrapper {
      order: 2;
    }

    &__img-wrapper,
    &__text-wrapper {
      img {
        margin: 0 auto;
      }
    }
  }
}
