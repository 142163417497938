.header {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 60px;
  z-index: 2000;
  
  background-color: #fff;
  border-bottom: 1px solid #000;

  &__menu-btn {
    display: none;
    position: relative;

    width: 18px;
    height: 14px;

    background-color: transparent;
    border: none;

    span {
      position: absolute;
      top: 6px;
      left: 0;
      display: block;
      width: 18px;

      font-size: 0;

      border: 1px solid #000;
      background-color: #000;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 18px;
      height: 2px;

      border: 1px solid #000;
      background-color: #000;
    }

    &::before {
      top: 0;
    }

    &::after {
      top: 12px;
    }
  }

  @media (max-width: 991px) {
    padding: 15px 25px;

    &__logo-link img {
      width: 80%;
    }

    &__link {
      font-size: 14px;
    }
    
    &__menu-wrapper--mobile {
      position: absolute;
      top: 71px;
      left: 0;
      z-index: 1000;
      width: 100%;
      max-height: 0;
      overflow: hidden;

      transition: max-height 0.2s linear;
    }
    
    &__menu-btn {
      display: block;
    }

    &__menu-btn--active ~ &__menu-wrapper--mobile {
      max-height: 100vh;
      transition: max-height 0.2s linear;
    }

    &__menu-btn--active {
      span {
        display: none;
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
  
      &::before,
      &::after {
        top: 7px;
      }
    }

    &__logo-link img {
      width: 80%;
    }

    &__link {
      font-size: 14px;
    }
  }

  @media (max-width: 767px) {
    &__logo-link img {
      width: 100%;
    }

    &__menu-wrapper--mobile {
      top: 81px;
    }
  }
}
