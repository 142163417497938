.gratitudes-page {
  padding: 100px 0 0;

  &__ticker {
    margin-bottom: 120px;
  }

  &__content-wrapper {
    padding: 0 60px;
  }

  &__lead-in {
    margin-bottom: 50px;
  }

  &__title {
    margin-bottom: 60px;

    @include font-title-small;
  }

  &__text {
    @include font-text-big;
  }

  &__tabs-wrapper {
    margin-bottom: 70px;
  }

  &__gratitudes-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    margin: 0 auto;
  }

  @media (max-width: 1260px) {
    padding: 60px 0 0;

    &__ticker {
      margin-bottom: 60px;
    }
  }

  @media (max-width: 768px) {
    &__content-wrapper {
      padding: 0 25px;
    }

    &__title {
      font-size: 24px;
      line-height: 40px;
    }
  
    &__text {
      font-size: 14px;
      line-height: 30px;
    }
  }
}
