.not-found-page {
  &__content {
    padding: 60px 60px;
    text-align: center;
  }
  
  &__title {
    @include font-title-small;
  }

  &__text {
    @include font-text-small;
  }

  &__link {
    font-weight: bold;
  }

  @media (max-width: 767px) {
    &__content {
      padding: 30px 25px;
    }
  }
}
