@font-face {
    font-family:'Merriweather';
    src: 
      url('../fonts/Merriweather Italic.woff2') format('woff2'),
      url('../fonts/Merriweather Italic.woff') format('woff'),
      url('../fonts/Merriweather Italic.ttf') format('truetype'),
      url('../fonts/Merriweather Italic.otf') format('opentype');
    font-weight: 400;
    font-style: italic;
    font-stretch: normal;
  }
  
  @font-face {
    font-family:'Merriweather';
    src: 
      url('../fonts/Merriweather Regular.woff2') format('woff2'),
      url('../fonts/Merriweather Regular.woff') format('woff'),
      url('../fonts/Merriweather Regular.ttf') format('truetype'),
      url('../fonts/Merriweather Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
  }

  @font-face {
    font-family:'Merriweather Light';
    src: url('../fonts/Merriweather Light.woff2') format('woff2'),
      url('../fonts/Merriweather Light.woff') format('woff'),
      url('../fonts/Merriweather Light.ttf') format('truetype'),
      url('../fonts/Merriweather Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
  }

@font-face {
  font-family:'Merriweather Light';
  src: url('../fonts/Merriweather Light Italic.woff2') format('woff2'),
    url('../fonts/Merriweather Light Italic.woff') format('woff'),
    url('../fonts/Merriweather Light Italic.ttf') format('truetype'),
    url('../fonts/Merriweather Light Italic.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
    font-stretch: normal;
  }
  
  @font-face {
    font-family:'Source Sans Pro';
    src: 
      url('../fonts/Source Sans Pro Italic.woff2') format('woff2'),
      url('../fonts/Source Sans Pro Italic.woff') format('woff'),
      url('../fonts/Source Sans Pro Italic.ttf') format('truetype'),
      url('../fonts/Source Sans Pro Italic.otf') format('opentype');
    font-weight: 400;
    font-style: italic;
    font-stretch: normal;
  }
  
  @font-face {
    font-family:'Source Sans Pro';
    src: 
      url('../fonts/Source Sans Pro Regular.woff2') format('woff2'),
      url('../fonts/Source Sans Pro Regular.woff') format('woff'),
      url('../fonts/Source Sans Pro Regular.ttf') format('truetype'),
      url('../fonts/Source Sans Pro Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
  }
  