.ticker {
  position: relative;
  height: 240px;

  overflow: hidden;

  &__item-text {
    position: absolute;
    width: max-content;
    height: 120px;
    margin: 0;
    z-index: 100;

    @include font-ticker;
  }

  &__container-ticker {
    animation: ticker 15s linear infinite;
  }

  &__container-ticker--reverse {
    animation-duration: 12s;
    animation-direction: reverse;
  }

  &__item-text--top {
    top: 0;
    left: 0;
  }

  &__item-text--bottom {
    top: 120px;
    right: 0;
  }

  &__item-text {
    left: unset;
    right: 0;
  }

  @media (max-width: 1260px) {
    height: 160px;

    &__item-text {
      font-size: 64px;
      line-height: 80px;
    }

    &__item-text--bottom {
      top: 80px;
    }
  }
  
  @media (max-width: 768px) {
    height: 120px;

    &__item-text {
      font-size: 48px;
      line-height: 60px;
    }

    &__container-ticker {
      animation-duration: 10s;
    }
  
    &__container-ticker--reverse {
      animation-duration: 8s;
    }

    &__item-text--bottom {
      top: 60px;
    }
  }
}

@keyframes ticker {
  from {
    transform: translateX(0);
  }
  
  to {
    transform: translateX(60%);
  }
}
