.search-item {
  margin-bottom: 30px;

  &__title {
    margin-bottom: 5px;

    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
  }

  &__text {
    font-size: 16px;
    line-height: 20px;
  }

  &__link {
    font-size: 14px;
    font-weight: 700;
    font-style: italic;
    color: $basic-blue;
    line-height: 18px;
  }
}
