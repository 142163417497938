.link-to-all {
  position: relative;
  display: block;
  width: max-content;
  padding: 12px 56px 12px 36px !important;

  @include font-link;

  border: 1px solid #000;
  border-radius: 50%;
  background-color: rgba(18, 66, 138, 0.1);

  z-index: 1000;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    background-color: #fff;
  }

  &::after {
    content: "";
    position: absolute;
    top: 18px;
    right: 33px;
    
    width: 12px;
    height: 12px;

    background-image: url(../img/mini-arrow.svg);
    background-repeat: no-repeat;
    transition: 0.2s;
  }

  &:hover::after,
  &:focus::after {
    transform: translateX(8px);
  }

  &:active::after {
    transform: translateX(20px);
  }
}

@media (max-width: 768px) {
  .link-to-all {
    margin: 0 auto;
  }
}
