.gratitudes-item {
  cursor: pointer;

  &__photo {
    margin-bottom: 10px;
  }

  &__title {
    @include font-text-big;
  }

  @media (max-width: 768px) {
    &__photo {
      img {
        margin: 0 auto;
      }
    }

    &__title {
      @include font-text-small;
    }
  }
}


