.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 70%;
  margin: 0 auto 100px;
  padding: 20px 48px;

  border-top: 1px solid #000;
  border-bottom: 1px solid #000;

  &__btn-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__pages-num-list {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
  
    width: auto;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__pages-num-link {
    display: block;
    width: 40px;
    height: 50px;

    @include font-subtitle;
    text-align: center;
  }

  &__pages-num-link--active {
    width: 60px;
    height: 50px;

    border: 1px solid #000;
    border-radius: 50%;
  }

  @media (max-width: 1260px) {
    width: 100%;

    &__pages-num-link {
      font-size: 16px;
    }
  }

  @media (max-width: 768px) {
    width: 100%;

    &__pages-num-link {
      font-size: 16px;
    }
  }
}
  