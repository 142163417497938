$font-title-family: Merriweather;
$font-text-family: "Source Sans Pro";
$font-light-family: "Merriweather Light";

$transform-menu-sidebar-item: 48px;
$header-height: 125px;
$header-height-mobile: 81px;
$footer-height: 83px;

$basic-blue: #12428a;
$font-ticker-light: 2px 2px 2px rgba($basic-blue, 0.2), -2px -2px 2px rgba($basic-blue, 0.2), 1px -1px 2px rgba($basic-blue, 0.2), -1px 1px 2px rgba($basic-blue, 0.2);
$font-ticker-dark: -1px 0 rgba($basic-blue, 1), 1px 0 rgba($basic-blue, 1), 0 -1px rgba($basic-blue, 1), 0 1px rgba($basic-blue, 1);
