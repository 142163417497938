@mixin font-ticker {
  font-family: $font-title-family;
  font-weight: normal;
  font-style: italic;
  font-size: 96px;
  line-height: 121px;
  color: #fff;
  text-transform: uppercase;
  text-shadow: $font-ticker-light;
}

@mixin font-title-big {
  font-family: $font-title-family;
  font-style: normal;
  font-weight: normal;
  font-size: 72px;
  line-height: 91px;
}

@mixin font-title-small {
  @include font-title-big;
  font-size: 36px;
  line-height: 48px;
}

@mixin font-subtitle {
  @include font-title-big;
  font-size: 20px;
  line-height: 48px;
}

@mixin font-text-inset {
  font-family: $font-light-family;
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 54px;
}

@mixin font-text-big {
  font-family: $font-text-family, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #000;
  line-height: 24px;
}

@mixin font-text-small {
  @include font-text-big;
  font-size: 14px;
  line-height: 30px;
}

@mixin font-menu {
  @include font-text-big;
  font-size: 18px;
  line-height: 23px;
}

@mixin font-link {
  font-family: $font-text-family, sans-serif;
  font-size: 13px;
  font-weight: 600;
  font-style: italic;
  line-height: 24px;
}

@mixin arrow-btn {
  content: "";

  position: absolute;
  width: 24px;
  height: 24px;

  background-image: url(../img/arrow.svg);
  transition: 0.2s;
}

@mixin arrow-btn-blue {
  content: "";
      
  position: absolute;
  width: 43px;
  height: 38px;

  background-image: url(../img/arrow-blue.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: 0.2s;
}
