.menu-contact-social {
  &__header {
    margin: 0 0 60px;

    @include font-title-small;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;

    list-style: none;
  }

  &__item {
    margin: 0 70px 45px 0;

    a {
      display: flex;

      svg {
        display: inline;
        margin: auto 28px auto 0;
      }
    }
  }

  &__title {
    margin: 0;

    @include font-subtitle;
  }

  @media (max-width: 768px) {
    &__header {
      font-size: 24px;
      line-height: 40px;
    }

    &__list {
      flex-direction: column;
    }

    &__item {
      margin: 0 0 50px 0;

      a {
        align-items: center;

        svg {
          margin-right: 36px;
        }
      }
    }

    &__title {
      font-size: 14px;
      line-height: 30px;
    }
  }
}
  