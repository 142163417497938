.gratitudes-item-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw !important;
  height: 100vh !important;
  overflow: auto;

  z-index: 5000;

  background-color: rgba(0, 0, 0, 0.4);
  transform: scale(0);
  transition: 0.5s;

  &__content {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 70%;
    width: fit-content;
    margin: 25px auto;
    padding: 40px 60px 40px 40px !important;

    background-color: #fff;
  }

  &__close-btn {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 16px;
    height: 16px;

    background-color: transparent;
    border: none;

    &::before,
    &::after {
      content: "";
      position: absolute;

      top: 7px;
      right: 0;
      width: 18px;
      height: 2px;
      
      background-color: #000;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  &__photo {
    img {
      width: auto;
      height: auto;
      margin: 0 auto;
    }
  }

  &__description {
    margin-left: 30px;
  }

  &__title {
    @include font-subtitle;
  }

  &__text {
    margin: 0;

    @include font-text-big;
    font-size: 14px;
  }

  &.active {
    transform: scale(1);
    transition: 0.5s;
  }

  @media (max-width: 1260px) {
    &__close-btn {
      top: 15px;
      right: 15px;
    }
  }

  @media (max-width: 768px) {
    &__content {
      flex-direction: column;
      max-width: 90%;
      padding: 40px !important;
    }

    &__photo {
      margin-right: 0;
      margin-bottom: 30px;
    }

    &__description {
      margin-left: 0;
    }

    &__title {
      @include font-text-big;     
    }
  
    &__text {
      @include font-text-small;
    }
  }
}
