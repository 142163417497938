.social-link-element {
  display: block;
  margin: 0 auto;

  &__logo {
    display: block;
  }

  @media (max-width: 1440px) {
    &__logo {
      width: 40px;
      height: 32px;
    }
  }
}
