.main-page-contacts-block {
  padding: 0 60px;

  &__wrapper {
    margin: 0 auto;
  }

  &__title {
    margin: 0 0 60px;

    @include font-title-big;
  }

  &__list-title {
    margin-bottom: 50px;
    display: inline-block;

    @include font-title-small;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    margin-bottom: 30px;
    padding-right: 100px !important;
  }

  &__item-label {
    position: relative;
    width: 100%;
    margin: 0;
    padding-right: 50px !important;

    @include font-subtitle;

    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      top: calc(50% - 12px);
      right: 0;
      
      width: 24px;
      height: 24px;
  
      background-image: url(../img/arrow.svg);
      background-repeat: no-repeat;
      background-position: center;
      transition: 0.2s;
      transform: rotate(45deg);
    }
  }

  &__close-tabs-control:hover ~ &__item-label::after {
    transform: rotate(-45deg);
  }

  &__item-description {
    position: relative;
    max-height: 0;
    overflow: hidden;

    transition: max-height 0.2s linear;

    p {
      margin: 0 0 20px;

      @include font-text-big;
    }

    p:first-child {
      margin-top: 30px;
    }
  }

  &__item:hover &__item-description,
  &__item:focus &__item-description {
    max-height: 100%;
    transition: max-height 0.3s linear;
  }

  &__item:hover &__item-label,
  &__item:focus &__item-label {
    &::after {
      transform: scale(1.5) rotate(-45deg);
    }
  }

  hr {
    display: block;
    opacity: 1;
    background-color: #000;
  }

  @media (max-width: 1199px) {
    &__wrapper {
      padding: 0 25px !important;
    }

    &__title {
      margin-bottom: 50px;
  
      font-size: 48px;
      line-height: 60px;
    }

    &__list-title {
      font-size: 30px;
      line-height: 48px;
    }

    &__item {
      margin-bottom: 30px;
      padding-right: 0 !important;
    }

    &__item-label {
      font-size: 16px;
      line-height: 48px;
    }

    &__item-description {
      p {
        @include font-text-small;
      }
    }
  }

  @media (max-width: 768px) {
    &__title {
      margin-bottom: 40px;
  
      font-size: 36px;
      line-height: 64px;
    }

    &__list-title {
      margin-bottom: 40px;

      font-size: 24px;
      line-height: 40px;
    }

    &__item-label {
      @include font-text-small;

      &::after {
        content: none;
      }
    }

    &__item-description {
      max-height: 100%;
    }

    &__close-tabs-control:hover ~ &__item-label::after {
      transform: unset;
    }
  }
}
