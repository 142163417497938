.main-page-gratitudes-block {
  position: relative;
  padding: 0 0 0 20px !important;

  &__title {
    margin: 0 0 50px;

    @include font-title-small;
  }

  .gratitudes-item__photo {
    margin: 0 auto 30px;
  }

  .slick-list {
    padding: 0 0 50px;
  }

  .slick-track {
    margin: 0 -15px;
    z-index: 1000;
  }

  .slick-slide {
    padding: 0 15px;
  }

  .slick-slide > div {
    position: relative;
  }

  .slick-prev, 
  .slick-next {
    width: 60px;
    height: 50px;
  
    top: unset;
    bottom: 0;
    left: unset;
    right: 60px;

    border: 1px solid #000;
    border-radius: 50%;

    z-index: 100;
    background-color: #fff;

    &::after { 
      @include arrow-btn;
      top: 12px;
      left: 10px;
    }

    &:hover::after,
    &:focus::after {
      transform: translateX(8px);
    }
  
    &:active::after {
      transform: translateX(16px);
    }
  }

  .slick-next {
    bottom: -25px;
  }

  .slick-prev {
    right: 135px;

    transform: rotate(180deg);
  }

  &__link-to-all-gratitudes {
    position: absolute;
    bottom: 0;
  }

  @media (max-width: 1260px) {
    &__title {
      font-size: 30px;
    }

    .slick-next {
      right: 45px;
    }

    .slick-prev {
      right: 115px;
    }
  }

  @media (max-width: 992px) {
    padding: 0 25px !important;

    .slick-next {
      right: 0;
    }

    .slick-prev {
      right: 70px;
    }
  }

  @media (max-width: 768px) {
    padding: 0 !important;

    &__title {
      padding: 0 25px !important;

      font-size: 24px;
      line-height: 40px;
    }

    &__slider {
      margin-bottom: 80px;
    }

    &__link-to-all-gratitudes {
      position: relative;
    }

    &__slider-wrapper {
      padding-bottom: 0;
    }

    .slick-list {
      padding: 0;
    }
  
    .slick-track {
      height: fit-content;
      margin: 0;
    }
    
    .slick-slide {
      padding: 0;
    }

    .slick-prev {
      left: calc(50% - 67px);
      right: unset;
      bottom: -50px;
    }

    .slick-next {
      bottom: -75px;
      right: calc(50% - 67px);
    }
  }

  @media (max-width: 400px) {
    .slick-slide {
      padding: 15px;
    }

    .slick-track {
      margin: 0 -30px;
    }
  }
}
