.menu-share {
  position: absolute;
  width: 150px;
  height: fit-content;
  margin: 0;
  padding: 14px 20px 12px !important;
  z-index: 2000;
  list-style: none;

  background-color: #fff;
  border: 1px solid #000;
  box-sizing: border-box;
  border-radius: 4px;

  .ya-share2__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ya-share2__list.ya-share2__list_direction_horizontal > .ya-share2__item {
    margin: 0;
  }

  .ya-share2__container_size_m .ya-share2__badge .ya-share2__icon {
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
  }

  .ya-share2__container_color-scheme_whiteblack .ya-share2__item_service_moimir .ya-share2__icon {
    background-image: url(../img/mail.svg);
    background-size: contain;
  }
}
