.menu-main {
  margin: 0;
  padding: 0;

  &__list-wrapper {
    margin-bottom: 20px;
  }

  &__list {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item:not(:last-child) {
    margin-right: 40px;
  }

  &__link {
    @include font-menu;
  }

  &__link--active {
    font-weight: 600;
    font-style: italic;
  }
  
  @media (max-width: 991px) {
    &--mobile {
      display: flex;
      flex-direction: column;
      height: 100vh;
      padding: 0 0 40px;
      padding: 40px 0;
      z-index: 1000;

      background-color: #fff;

      .menu-social {
        max-width: 280px;
        margin: 0 auto;
      }
    }

    &__list-wrapper {
      order: 2;
      margin: 0 auto 80px;
    }

    &__list {
      flex-direction: column;
    }

    &__item:not(:last-child) {
      margin: 0 0 40px;
    }

    &__link {
      font-size: 14px;
    }
  }

  @media (max-width: 767px) {
    &__link {
      font-size: 18px;
    }
  }
}
