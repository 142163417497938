.news-list-page {
  padding: 100px 0 0;

  &__ticker {
    margin-bottom: 120px;
  }

  @media (max-width: 1260px) {
    padding: 60px 0 0;

    &__ticker {
      margin-bottom: 60px;
    }
  }
}