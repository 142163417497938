.main-page-news-block {
  .news-item {
    margin-bottom: 120px;
  }

  &__link-to-all-news {
    position: relative;
    display: block;
    width: fit-content;
    margin: 0 auto;
    padding: 0 83px !important;

    @include font-ticker;
    font-size: 48px;
    line-height: 60px;
    text-transform: uppercase;
    text-shadow: $font-ticker-dark;

    &:hover,
    &:focus {
      span {
        color: #fff;
        opacity: 0.5;
      }
    }

    &:active {
      span {
        color: rgba(18, 66, 138, 20%);
        opacity: 0.2;
      }
    }

    &::before,
    &::after {
      top: 11px;

      @include arrow-btn-blue;
      background-size: contain;
    }

    &::before {
      left: 0;
      transform: rotate(180deg);
    }

    &::after {
      right: 0;
    }

    &:hover::before,
    &:focus::before {
      transform: rotate(180deg) translateX(-20px);
      opacity: 0.5;
    }

    &:hover::after,
    &:focus::after {
      opacity: 0.5;
      transform: translateX(-20px);
    }
  
    &:active::before,
    &:active::after {
      opacity: 1;
      background-image: url(../img/arrow-blue-full.svg);
    }
  }

  @media (max-width: 1400px) {
    &__link-to-all-news {
      margin: 0 auto 0 0;
    }
  }

  @media (max-width: 1260px) {
    .news-item {
      margin-bottom: 30px;
    }

    &__link-to-all-news {
      padding: 0 65px !important;

      font-size: 30px;
      line-height: 38px;

      &::before,
      &::after {
        top: 2px;

        width: 30px;
      }
    }

    & {
      .news-item,
      .news-item:nth-child(even) {
        .news-item__description-wrapper {
          flex-direction: row;
        }
    
        .news-item__text-wrapper {
          order: 1;
        }
    
        .news-item__photo {
          order: 2;
        }
      }
    }
  }

  @media (max-width: 991px) {
    padding: 0 25px !important;

    &__link-to-all-news {
      margin: 0 auto;
    }

    .news-item,
    .news-item:nth-child(even) {
      .news-item__date,
      .indent {
        display: none;
      }

      .news-item__title,
      .news-item__description-wrapper,
      .news-item__photo {
        width: 100%;
      }
    }
  }

  @media (max-width: 768px) {
    .news-item {
      margin-bottom: 70px;
    }

    padding: 0 !important;

    &__link-to-all-news {
      padding: 0 !important;

      font-size: 25px;
      line-height: 31px;

      &::before,
      &::after {
        content: none;
      }
    }

    .news-item,
    .news-item:nth-child(even) {
      .news-item__date,
      .indent {
        display: none;
      }

      .news-item__description-wrapper {
        flex-direction: column;
      }

      .news-item__title,
      .news-item__description-wrapper {
        width: 100%;
      }
  
      .news-item__text-wrapper {
        order: 2;
      }
  
      .news-item__photo {
        order: 1;
      }
    }
  }
}
