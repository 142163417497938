.search-form {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__label {
    width: 100%;

    span {
      margin-right: 20px;
      @include font-text-big;
    }

    ::placeholder {
      @include font-text-big;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &__input {
    width: 70%;
    border: none;
  }

  &__input--error {
    border: 1px solid red;
    border-radius: 5px;
  }

  &__btn {
    flex-shrink: 0;
    position: relative;
    margin-left: 20px;
    margin-right: 0;
    z-index: 100;

    top: 0;
    left: 0;
    width: 60px;
    height: 50px;
  
    border: 1px solid #000;
    border-radius: 50%;
    background-color: transparent;

    &::after { 
      @include arrow-btn;
      top: 12px;
      left: 10px;
    }

    &:hover::after,
    &:focus::after {
      transform: translateX(8px);
    }
  
    &:active::after {
      transform: translateX(16px);
    }
  }

  @media (max-width: 992px) {
    order: 1;
    justify-content: space-between;
    width: auto;
    margin: 0 auto 80px;
    padding: 15px 25px !important;

    border-bottom: 1px solid #000;
    border-top: none;

    &__btn {
      flex-shrink: 0;
      margin-left: 0;
    }

    &__label {
      span {
        margin-right: 10px;
      }

      span,
      ::placeholder {
        font-size: 14px;
        line-height: 30px;
      }
    }
  }
}