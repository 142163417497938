.slider-main {
  position: relative;
  background-color: #12428A;
  max-height: 720px;

  &__slider-wrapper {
    position: relative;
  }

  &__description {
    position: absolute;
    bottom: 175px;
  }

  .slick-slide {
    display: flex;
    justify-content: flex-end;
    padding-left: 380px;
    
    width: 100%;
  }

  &__slider-img {
    width: auto;
  }

  &__text-name,
  &__text-job {
    margin: 0;
    padding: 0;
    
    color: #fff;
  }

  &__text-job {
    position: absolute;
    bottom: 40px;
    left: -305px;
    z-index: 1000;
  }
  
  &__link {
    color: #fff;
  }

  &__link:hover,
  &__link:focus,
  &__link:active {
    color: #fff;
    opacity: 0.8;
  }

  &__text-wrapper {
    position: absolute;
    bottom: 190px;
    left: 70px;
    z-index: 100;
  }

  &__text-name {
    @include font-title-big;
  }

  &__text-job {
    @include font-subtitle;
  }

  .slick-prev, 
  .slick-next {
    position: absolute;
    top: 120px;
    z-index: 100;

    width: 60px;
    height: 50px;
    margin-right: 24px;
  
    border: 1px solid #fff;
    border-radius: 50%;
    transform-origin: 50% 25%;

    &::after,
    &::before {
      content: none;
    }

    &::after { 
      @include arrow-btn;
      background-image: url(../img/arrow-white.svg);
      top: 12px;
      right: 10px;
    }

    &:hover::after,
    &:focus::after {
      transform: translateX(-8px);
    }
  
    &:active::after {
      transform: translateX(-16px);
    }
  }

  .slick-next {
    left: 150px;

    transform: rotate(180deg);
  }

  .slick-prev {
    left: 70px;
  }

  .slick-dots {
    display: flex !important;
    align-items: center;
    justify-content: center;
    box-sizing: content-box;
    width: 250px;
    height: 6px;

    top: 45px;
    left: 70px;

    li {
      width: 48px;
      height: 3px;
      padding: 0;

      button {
        width: 48px;
        height: 100%;

        &::before {
          content: "";
          width: 48px;
          height: 100%;
          
          background-color: #fff;
          opacity: 1;
          border-radius: 0;
        }
      }
    }

    li.slick-active {
      height: 6px;
    }

    &::before,
    &::after {
      position: absolute;
      @include font-subtitle;
      color: #fff;
    }

    &::before {
      content: "01";
      left: 0;
    }

    &::after {
      content: "03";
      right: 0;
    }
  }
}

@media (max-width: 1440px) {
  .slider-main {
    &__text-wrapper {
      bottom: 150px;
    }

    &__text-job {
      bottom: 45px;
      left: -185px;

      font-size: 16px;
      line-height: 30px;
    }
  
    &__text-name {
      font-size: 48px;
      line-height: 60px;
    }
  
    .slick-slide {
      padding-left: 256px;
    }
  }
}

@media (max-width: 1260px) {
  .slider-main {
    .slick-slide {
      padding-left: 270px;
    }

    &__text-wrapper {
      bottom: 120px;
      left: 25px;
    }

    &__text-job {
      bottom: 25px;
      left: -240px;
    }

    .slick-dots {
      width: 225px;
      top: 30px;
      left: 25px;

      &::before,
      &::after {
        font-size: 16px;
        line-height: 48px;
      }
    }

    .slick-prev, 
    .slick-next {
      top: 80px;
    }

    .slick-prev {
      left: 25px;
    }

    .slick-next {
      left: 100px;
    }
  }
}

@media (max-width: 991px) {
  .slider-main {
    &__text-wrapper {
      bottom: 100px;
    }

    &__text-name {
      font-size: 36px;
      line-height: 64px;
    }

    &__text-job {
      line-height: 24px;
    }
  }
}

@media (max-width: 767px) {
  .slider-main {
    max-height: unset;
    height: auto;

    &__slider-img {
      margin-top: -1px;
    }

    &__text-wrapper {
      position: absolute;
      height: fit-content;
      bottom: 180px;
      left: unset;
      padding: 10px 25px 0 !important;
    }

    &__text-name {
      font-size: 36px;
      line-height: 64px;
    }
  
    &__text-job {
      bottom: -160px;
      left: 25px;

      font-size: 18px;
      line-height: 30px;
    }

    .slick-slide {
      padding-left: 0;
      padding-bottom: 250px;
    }
    
    .slick-prev, 
    .slick-next {
      top: unset;
      bottom: 0;
      left: 25px;
    }

    .slick-next {
      left: 100px;
    }

    .slick-dots {
      top: unset;
      bottom: 260px;
      left: calc(50% - 110px);
    }
  }
}
