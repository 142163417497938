.loading-spinner {
  width: 75px;
  margin: 100px auto ;
  text-align: center;
  
  @media (max-width: 768px) {
    margin-top: 0;
  }
}

.spinner {
  position: relative;
  width: 75px;
  height: 75px;

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
  }

  @keyframes rotate-animation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes anti-rotate-animation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(-360deg);
    }
  }
  
  &:before {
    width: 65px;
    height: 65px;
    border-bottom-color: rgba($basic-blue, 0.4);
    border-right-color: rgba($basic-blue, 0.4);
    border-top-color: rgba($basic-blue, 0);
    border-left-color: rgba($basic-blue, 0);
    top: 0px;
    left: 0px;
    animation: rotate-animation 1s linear 0s infinite;
  }
  
  &:after {
    width: 45px;
    height: 45px;
    border-bottom-color: rgba($basic-blue, 0.4);
    border-right-color: rgba($basic-blue, 0.4);
    border-top-color: rgba($basic-blue, 0);
    border-left-color: rgba($basic-blue, 0);
    top: 10px;
    left: 10px;
    animation: anti-rotate-animation 0.85s linear 0s infinite;
  }
}
