.about-page {
  padding: 100px 0 0;

  &__ticker {
    margin-bottom: 120px;
  }

  &__title {
    margin-bottom: 80px;
    z-index: 100;

    @include font-title-big;
  }

  &__content-wrapper {
    padding: 0 60px;
  }

  &__description {
    position: relative;
  }

  &__description-part {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 200px;

    div {
      width: 50%;

      &:nth-child(odd) {
        margin-right: 100px;
      }
    }
  }

  &__description-part--last {
    align-items: flex-start;

    div {
      width: 100%;
    }

    img {
      margin-bottom: 30px;
    }
  }

  &__img {
    display: block;
    width: auto;
  }

  &__text {
    margin: 0;

    @include font-text-big;
    line-height: 30px;
  }

  &__description-inset {
    margin: 0 0 200px;
    padding: 0 300px !important;

    @include font-text-inset;
    font-style: italic;
    text-align: center;

    p {
      position: relative;
      margin: 0;
      z-index: 100;
    }
  }

  &__description--1964,
  &__description-inset--1986,
  &__description-part--2001,
  &__description-part--2002,
  &__description-part--2003,
  &__description-part--2021 {
    position: relative;

    &::before {
      position: absolute;
      left: -25%;

      @include font-ticker;
      font-size: 240px;
    }
  }

  &__description--1964 {
    &::before {
      content: "1964";
      top: 0;
    }
  }

  &__description-inset--1986 {
    position: relative;

    &::before {
      content: "1986";
      top: -100px;
      left: -4%;
    }

    &::after {
      content: "1997";
      position: absolute;
      bottom: -80px;
      right: -4%;

      @include font-ticker;
      font-size: 240px;
    }
  }

  &__description-part--2001 {
    &::before {
      content: "2001";
      bottom: 0;
    }
  }

  &__description-part--2002 {
    &::before {
      content: "2002";
      top: -150px;
    }
  }

  &__description-part--2003 {
    &::before {
      content: "2003";
      top: -100px;
    }

    &::after {
      content: "2021";
      position: absolute;
      bottom: -150px;
      right: -4%;

      @include font-ticker;
      font-size: 240px;
    }
  }

  &__img-wrapper {
    z-index: 100;
  }

  &__text-wrapper {
    z-index: 100;
  }

  &__menu-contact-social {
    margin-bottom: 150px;
  }

  @media (max-width: 1440px) {
    &__description-inset {
      padding: 0 100px !important;
    }
  }

  @media (max-width: 1260px) {
    padding: 60px 0 0;

    &__ticker {
      margin-bottom: 60px;
    }

    &__content-wrapper {
      padding: 0 45px;
    }

    &__description-inset {
      padding: 70px 0;
    }

    &__description--1964,
    &__description-inset--1986,
    &__description-part--2001,
    &__description-part--2002,
    &__description-part--2003,
    &__description-part--2021 {
      &::before {
        left: 0;
  
        font-size: 150px;
      }

      &::after {
        font-size: 150px;
      }
    }

    &__description-inset--1986 {
      &::before {
        top: -40px;
      }
  
      &::after {
        bottom: 0;
      }
    }

    &__description-part--2001 {
      &::before {
        top: -160px;
      }
    }

    &__description-part--2002 {
      &::before {
        top: -150px;
      }
    }

    &__description-part--2003 {
      &::before {
        top: 0;
        right: 0;
        left: unset;
      }

      &::after {
        content: none;
      }
    }
  }

  @media (max-width: 991px) {
    &__content-wrapper {
      padding: 0 25px;
    }

    &__title {
      margin-bottom: 100px;
    }

    &__description-part {
      flex-direction: column;
      margin-bottom: 90px;

      div {
        width: 100%;

        &:first-child {
          margin-right: 0;
        }
      }
    }

    &__description-part--last {
      div:first-child {
        margin-bottom: 30px;
      }
    }

    &__img-wrapper {
      order: 1;

      img {
        margin: 0 auto;
      }

      margin-bottom: 30px;
    }

    &__text-wrapper {
      order: 2;
    }

    &__description-inset {
      margin: 0 auto 100px;
      padding: 0 !important;
    }

    &__description--1964,
    &__description-inset--1986,
    &__description-part--2001,
    &__description-part--2002,
    &__description-part--2003,
    &__description-part--2021 {
      &::before {
        left: 0;
  
        font-size: 72px;
      }
    }
  
    &__description--1964 {
      &::before {
        top: -90px;
      }

      .about-page__description-part:first-child {
        position: relative;

        &::before {
          content: "1997";
          position: absolute;
          bottom: -100px;
    
          @include font-ticker;
          font-size: 72px;
        }
      }
    }
  
    &__description-inset--1986 {
      margin-bottom: 150px;

      &::before {
        top: -60px;
      }
  
      &::after {
        bottom: -60px;
  
        font-size: 72px;
      }
    }
  
    &__description-part--2001 {
      &::before {
        content: none;
      }

      .about-page__text-wrapper {
        position: relative;

        &::before {
          content: "2001";
          position: absolute;
          top: -60px;

          @include font-ticker;
          font-size: 72px;
        }
      }

      p {
        position: relative;
        z-index: 100;
      }

      .about-page__img-wrapper {
        margin-bottom: 70px;
      }
    }
  
    &__description-part--2002 {
      &::before {
        top: -100px;
      }
    }
  
    &__description-part--2003 {
      &::before {
        content: none;
      }

      .about-page__text-wrapper {
        position: relative;

        &::before {
          content: "2003";
          position: absolute;
          top: -60px;

          @include font-ticker;
          font-size: 72px;
        }
      }

      p {
        position: relative;
        z-index: 100;
      }

      .about-page__img-wrapper {
        margin-bottom: 70px;
      }
    }
  }

  @media (max-width: 768px) {
    &__title {
      font-size: 24px;
      line-height: 40px;
    }

    &__text {
      font-size: 14px;
    }

    &__description {
      margin-bottom: 100px;
    }

    &__description-part {
      margin-bottom: 0;
    }

    &__description-inset {
      font-size: 18px;
      line-height: 48px;
    }

    &__description:nth-last-child(n) {
      .about-page__description-part:not(:last-child) {
        margin-bottom: 100px;
      }
    }

    &__description--1964 {
      margin-bottom: 170px;

      &::before {
        top: -100px;
      }

      .about-page__description-part:first-child {
        &::before {
          bottom: -125px;
          left: 0;
        }
      }
    }

    &__description-inset--1986 {
      &::before {
        top: -110px;
      }
  
      &::after {
        bottom: -90px;
  
        font-size: 72px;
      }
    }

    &__description-part--2001 {
      .about-page__text-wrapper {
        &::before {
          top: -70px;
        }
      }
    }

    &__description-part--2002 {
      &::before {
        top: -90px;
      }
    }

    &__description-part--2003 {
      .about-page__text-wrapper {
        &::before {
          top: -70px;
        }
      }
    }
  }
}
