.date-sorting-tabs {
  display: flex;
  justify-content: space-between;

  &__header-wrapper {
    margin: 0;
  }

  &__header {
    margin: 0 30px 0 0;

    @include font-subtitle;
  }

  &__close-tabs-icon {
    display: none;
  }

  &__list {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
  
    list-style: none;
  }

  &__item {
    width: fit-content;
    margin-right: 45px;
  }

  &__link {
    display: block;
    height: 50px;
    padding: 0 15px !important;

    @include font-subtitle;
    text-align: center;
    
    background-color: transparent;
  }

  &__link--active {
    width: fit-content;
    padding: 0 15px !important;
    border: 1px solid #000;
    border-radius: 50%;
  }

  hr {
    display: none;
  }

  @media (max-width: 1260px) {
    &__header {
      font-size: 16px;
      line-height: 48px;
    }

    &__item {
      margin-right: 5px;
    }

    &__link {
      font-size: 16px;
      line-height: 48px;
    }
  }

  @media (max-width: 768px) {  
    &__header-wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 20px;
    }

    &__header {
      margin: 0;

      font-size: 18px;
      line-height: 30px;
    }

    &__close-tabs-icon {
      position: relative;
      display: block;

      width: 24px;
      height: 24px;
      background-color: transparent;
      border: none;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        
        width: 24px;
        height: 24px;
    
        background-image: url(../img/arrow.svg);
        background-repeat: no-repeat;
        background-position: center;
        transition: 0.2s;
        transform: rotate(45deg);
      }
    }

    &__close-tabs-icon--active::after {
      transform: rotate(-45deg);
      transform-origin: center;
    }

    &__item {
      margin-right: 0;
    }

    &__list {
      width: 100%;
      margin-bottom: 30px;
    }

    &__link {
      font-size: 18px;
    }

    hr {
      display: block;
      margin: 0;
      opacity: 1;
      background-color: #000;
    }
  }
}
