.main-gratitudes-screen {
  &__gratitudes-list {
    margin-bottom: 70px;
  }

  .gratitudes-item {
    &__photo {
      img {
        max-width: 352px;
      }
    }
  }
}
