.news-item {
  &__news-header {
    margin-bottom: 70px;
  }

  &__date {
    margin: 0;

    @include font-title-small;
    font-style: italic;
    line-height: 54px;
  }

  &__description-wrapper {
    display: flex;
  }

  &__title {
    margin: 0;

    @include font-title-small;
  }

  &__text-wrapper {
    width: 45%;
    margin-right: 100px;
  }

  &__text {
    display: block;
    margin-bottom: 50px;

    @include font-text-big;
  }

  &__photo {
    width: 35%;

    img {
      width: 100%;
      margin: 0 auto;
    }
  }
  
  &:nth-child(even) {
    .news-item__text-wrapper {
      order: 2;
      margin: 0 0 0 100px;
    }

    .news-item__photo {
      order: 1;
      margin: 0 0 0 10%;
    }

    .news-item__title {
      padding: 0 0 0 20%;
    }
  }


  @media (max-width: 1260px) {
    &,
    &:nth-child(even) {
      .news-item__text-wrapper {
        order: 2;
        width: 100%;
        margin: 0 30px 0 0;
      }
  
      .news-item__photo {
        order: 1;
        width: 80%;
        margin: 0 0 40px 0;
      }
  
      .news-item__title {
        padding-left: 0;
      }
    }

    &__news-header {
      margin-bottom: 60px;
    }

    &__date {
      padding-top: 10px;
      font-size: 16px;
      line-height: 36px;
    }

    &__title {
      font-size: 30px;
      line-height: 48px;
    }

    &__description-wrapper {
      flex-direction: column;
    }
  }

  @media (width: 768px) {
    padding: 0 25px !important;
  }

  @media (max-width: 767px) {
    &,
    &:nth-child(even) {
      .news-item__photo {
        width: 100%;
      }

      .news-item__text-wrapper {
        margin: 0;
        padding: 0 26px !important;
      }

      .news-item__title {
        order: 1;
        margin-bottom: 0;
  
        font-size: 24px;
        line-height: 40px;
      }
    }

    &__news-header {
      margin-bottom: 30px;
      padding: 0 26px !important;
    }
    
    &__date {
      order: 2;
      padding: 0;

      font-size: 18px;
      line-height: 48px;
    }

    &__text {
      font-size: 14px;
      line-height: 30px;
    }
  }
}
