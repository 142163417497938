.search-result-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__content {
    padding: 60px 60px;
  }
  
  &__title {
    margin-bottom: 40px;

    @include font-title-small;
    text-align: center
  }

  &__text {
    @include font-text-small;
  }

  &__search-query {
    font-weight: bold;
    font-style: italic;
  }

  &__result-list {
    margin: 0;
    padding: 0;
    
    list-style: none;
  }

  @media (max-width: 767px) {
    &__content {
      padding: 30px 25px;
    }
  }
}
