@import 'fonts';
@import 'variables';
@import 'mixins';


@import '../../components/elements/pagination-nav-btn/pagination-nav-btn';
@import '../../components/elements/social-link/social-link';
@import '../../components/elements/link-to-all/link-to-all';
@import '../../components/elements/ticker/ticker';
@import '../../components/elements/loading-spinner/loading-spinner';

@import '../../components/blocks/date-sorting-tabs/date-sorting-tabs';
@import '../../components/blocks/footer/footer';
@import '../../components/blocks/header/header';
@import '../../components/blocks/menu-sidebar/menu-sidebar';
@import '../../components/blocks/menu-contact-social/menu-contact-social';
@import '../../components/blocks/menu-main/menu-main';
@import '../../components/blocks/menu-share/menu-share';
@import '../../components/blocks/menu-social/menu-social';
@import '../../components/blocks/news-item/news-item';
@import '../../components/blocks/pagination/pagination';
@import '../../components/blocks/gratitudes-item/gratitudes-item';
@import '../../components/blocks/news-category-tabs/news-category-tabs';
@import '../../components/blocks/search-form/search-form';
@import '../../components/blocks/slider-main/slider-main';
@import '../../components/blocks/main-page-about-block/main-page-about-block';
@import '../../components/blocks/main-page-contacts-block/main-page-contacts-block';
@import '../../components/blocks/main-page-news-block/main-page-news-block';
@import '../../components/blocks/main-page-gratitudes-block/main-page-gratitudes-block';
@import '../../components/blocks/search-item/search-item';

@import '../../components/screens/news-screen/news-screen';
@import '../../components/screens/main-gratitudes-screen/main-gratitudes-screen';
@import '../../components/screens/gratitudes-by-year-screen/gratitudes-by-year-screen';

@import '../../components/modals/gratitudes-item-modal/gratitudes-item-modal';

@import '../../components/pages/about-page/about-page';
@import '../../components/pages/contacts-page/contacts-page';
@import '../../components/pages/gratitudes-page/gratitudes-page';
@import '../../components/pages/main-page/main-page';
@import '../../components/pages/news-item-page/news-item-page';
@import '../../components/pages/news-list-page/news-list-page';
@import '../../components/pages/not-found-page/not-found-page';
@import '../../components/pages/search-result-page/search-result-page';
@import '../../components/pages/loading-page/loading-page';


body {
  position: relative;
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

#root {
  height: 100%;
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

* {
  box-sizing: border-box;

  a:hover,
  a:focus,
  a:active {
    color: #000;
  }

  a {
    text-decoration: none;
    color: #000;
  }
}

.content {
  flex: 1 0 auto;
  min-height: calc(100vh - ($header-height + $footer-height));
  padding-left: 0;
  padding-right: 0;
}

.hidden {
  display: none;
}

.body-lock {
  overflow: hidden;
}

.row {
  margin-left: 0;
  margin-right: 0;

  * {
    padding-left: 0;
    padding-right: 0;
  }
}

.visually-hidden:not(:focus):not(:active),
input[type="checkbox"].visually-hidden,
input[type="radio"].visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  -webkit-clip-path: inset(100%);
          clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

@media (max-width: 768px) {
  .content {
    min-height: calc(100vh - ($header-height-mobile + $footer-height));
  }
}
