.pagination-nav-btn {
  display: block;
  position: relative;
  width: 60px;
  height: 50px;

  border: 1px solid #000;
  border-radius: 50%;

  &::before {
    @include arrow-btn;
    top: 12px;
    left: 10px;

    transition: 0.2s;
  }

  &--double {
    width: 80px;

    &::after {
      @include arrow-btn;
      top: 12px;
      left: 30px;
  
      transition: 0.2s;
    }
  }

  &--backward {
    transform: rotate(180deg);
  }

  &:hover::before,
  &:focus::before,
  &:hover::after,
  &:focus::after {
    transform: translateX(8px);
  }

  &:active::before,
  &:active::after {
    transform: translateX(16px);
  }
}
