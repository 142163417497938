.main-page {
  &__heading {
    margin-bottom: 160px;
    padding: 60px 60px 0;
  }

  &__ticker {
    margin-bottom: 120px;
  }

  &__section-wrapper {
    margin-bottom: 160px;
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  &__section-wrapper--no-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  &__menu-sidebar {
    position: relative;

    background-color: #fff;
  }

  &__menu-social-wrapper {
    border-top: 1px solid #000;
  }

  &__about-block {
    padding: 0 0 200px;
  }

  @media (max-width: 1260px) {
    &__heading {
      padding: 45px 25px 0;
    }

    &__ticker {
      margin-bottom: 60px;
    }

    &__section-wrapper {
      margin-bottom: 120px;
      padding-left: 45px !important;
      padding-right: 45px !important;
    }

    &__section-wrapper--no-padding {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    &__heading,
    &__section-wrapper {
      margin-bottom: 120px;
    }
  }

  @media (max-width: 991px) {
    &__section-wrapper {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    &__heading,
    &__section-wrapper {
      margin-bottom: 100px;
    }
 
    &__menu-sidebar {
      display: none;
    }
  }

  @media (max-width: 767px) {
    &__heading {
      padding: 0;
    }
  }
}
