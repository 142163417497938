.news-screen {
  padding: 0 60px;

  &__news-item-wrapper {
    padding: 0;

    .news-item {
      margin: 70px 0 140px;
    }
  }

  &__news-item-wrapper ~ .loading-spinner {
    margin-top: 40px;
  }

  &__news-item-empty {
    margin: 30px 0;

    @include font-text-big;
    text-align: center;
  }

  @media (max-width: 1260px) {
    padding: 0 45px !important;

    &__news-item-wrapper {
      .news-item {
        margin-bottom: 100px;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 0 !important;

    .date-sorting-tabs {
      padding: 0 25px !important;
    }

    &__news-item-empty {
      @include font-text-small;
    }
  }
}
