.menu-sidebar {
  position: fixed;
  height: calc(100vh - 178px);
  // height: calc(100vh - 134px);

  z-index: 4000;
  background-color: #fff;

  &__list {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: calc(100% - 134px);
    margin: 0 0 45px;

    list-style: none;

    &::before {
      content: "";
      position: absolute;
      top: 50px;
      width: 100%;
      height: 1px;

      background-color: #000;

      transition: all 0.3s linear;
    }

    &::after {
      @include arrow-btn;
      top: 12px;
      right: 15px;

      transition: all 0.3s linear;
    }
  }

  &__item {
    margin-bottom: 0;
    padding-right: 55px !important;
  }

  &__list--slider {
    .menu-sidebar__item--slider {
      flex-grow: 1;
      margin-bottom: auto;
    }
  }

  &__list--about { 
    &::before,
    &::after {
      transform: translateY(#{$transform-menu-sidebar-item});
    }

    .menu-sidebar__item--about {
      flex-grow: 1;
      margin-bottom: auto;
    }
  }

  &__list--dumaNews {
    &::before,
    &::after {
      transform: translateY(calc(#{$transform-menu-sidebar-item}*2));
    }

    .menu-sidebar__item--dumaNews {
      flex-grow: 1;
      margin-bottom: auto;
    }
  }

  &__list--regionNews {
    &::before,
    &::after {
      transform: translateY(calc(#{$transform-menu-sidebar-item}*3));
    }

    .menu-sidebar__item--regionNews {
      flex-grow: 1;
      margin-bottom: auto;
    }
  }

  &__list--gratitudes {
    &::before,
    &::after {
      transform: translateY(calc(#{$transform-menu-sidebar-item}*4));
    }

    .menu-sidebar__item--gratitudes {
      flex-grow: 1;
      margin-bottom: auto;
    }
  }

  &__list--contacts {
    &::before,
    &::after {
      transform: translateY(calc(#{$transform-menu-sidebar-item}*5));
    }

    .menu-sidebar__item--contacts {
      flex-grow: 1;
      margin-bottom: auto
    }
  }

  &__link {
    @include font-subtitle;
  }

  @media (max-width: 1440px) {
    &__item {
      padding-right: 40px !important;
    }
    
    &__list {
      &::after {
        right: 0;
      }
    }

    &__link {
      font-size: 16px;
      line-height: 48px;
    }
  }

  @media (max-width: 1260px) {
    height: calc(100vh - 163px);
    // height: calc(100vh - 119px);

    &__link {
      font-size: 16px;
      line-height: 48px;
    }
  }

  @media (max-width: 1024px) {
    &__item {
      padding-right: 30px !important;
    }
  }

  @media (min-width: 1921px) {
    width: 350px;
  }
}
