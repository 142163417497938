.news-category-tabs {
  margin-bottom: 50px;

  &__list {
    display: flex;
    align-items: baseline;
    justify-content: center;
    margin: 0;
    padding: 0;
  
    list-style: none;
  }

  &__item {
    margin-right: 76px;
    order: 3;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &__item--active {
    order: 1;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  &__link,
  &__item-separate {
    @include font-title-small;
    color: #000;
  }

  &__item-separate {
    order: 2;
  }

  &__link--active {
    @include font-title-big;
    cursor: default;
  }

  @media (max-width: 1260px) {
    &__link,
    &__item-separate {
      font-size: 30px;
      line-height: 48px;
    }

    &__link--active {
      font-size: 48px;
      line-height: 60px;
    }
  }

  @media (max-width: 768px) {
    padding: 0 26px;
    
    &__item {
      margin-right: 20px;
    }
    
    &__link,
    &__item-separate {
      font-size: 24px;
      line-height: 40px;
    }

    &__link--active {
      font-size: 36px;
    }
  }
}
