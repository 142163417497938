.news-item-page {
  &__news-wrapper {
    position: relative;
    padding: 100px 60px;
  }

  &__title--mobile,
  &__date--mobile {
    display: none;
  }

  &__photo {
    margin: 0 auto 20px;
  }

  &__date-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 70px;
  }

  &__date {
    margin: 0;

    @include font-subtitle;
  }

  &__link-to-all-wrapper {
    a {
      margin: 0 auto;
    }
  }

  &__share-btn {
    margin: 0;
    padding: 0;
    width: fit-content;
    height: fit-content;

    @include font-subtitle;
    text-decoration: underline;
    background-color: transparent;
    border: none;
  }

  &__title {
    margin: 0 0 60px;

    @include font-title-small;
  }

  &__text-wrapper {
    margin: 0 0 120px;
    padding-right: 100px;
  }

  &__text {
    margin-bottom: 45px;

    @include font-text-big;
    line-height: 30px;
  }

  @media (max-width: 1921px) {
    &__text-wrapper {
      padding-right: 0;
    }
  }

  @media (max-width: 1260px) {
    &__title {
      font-size: 30px;
      line-height: 48px;
    }

    &__share-btn,
    &__date {
      font-size: 16px;
      line-height: 48px;
    }

    &__photo {
      margin-bottom: 0;
    }
  }

  @media (max-width: 991px) {
    &__news-wrapper {
      padding: 50px 25px;
    }

    &__date-wrapper {
      margin-bottom: 70px;
    }

    &__title,
    &__date {
      display: none;
    }

    &__title--mobile,
    &__date--mobile {
      display: block;
    }

    &__title--mobile {
      margin-bottom: 20px;
    }

    &__date--mobile {
      margin-bottom: 40px;
    }
    
    &__photo-wrapper {
      position: relative;
    }

    &__photo {
      margin-bottom: 20px;
    }

    &__link-to-all-wrapper {
      position: relative;

      a {
        position: absolute;
        top: -115px;
        right: 0;
      }
    }
  }

  @media (max-width: 768px) {
    &__photo {
      margin-bottom: 10px;
    }

    &__link-to-all-wrapper {
      a {
        top: -70px;
        right: calc(50% - 117px);
      }
    }

    &__date-wrapper {
      margin-bottom: 150px;
    }
    
    &__text-wrapper {
      margin-bottom: 100px;
    }
  }
}
